import { Routes } from '@angular/router';

import { AppointmentChangeComponent } from './appointmentchange.component';

export const AppointmentChangeRoutes: Routes = [
    {
        path: 'appointmentchange',
        component: AppointmentChangeComponent,
        pathMatch: 'full'
    },
    {
        path: 'appointmentchange/:agencyKey/:listingEnquiryID',
        component: AppointmentChangeComponent,
        pathMatch: 'full'
    }
];