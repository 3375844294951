import { Routes } from '@angular/router';

import { AppointmentComponent } from './appointment.component';

export const AppointmentRoutes: Routes = [
    {
        path: ':agencyKey/:listingEnquiryID',
        component: AppointmentComponent,
        pathMatch: 'full'
    },
    {
        path: '',
        component: AppointmentComponent,
        pathMatch: 'full'
    },
    {
        path: 'appointment',
        component: AppointmentComponent,
        pathMatch: 'full'
    },
    {
        path: 'appointment/:agencyKey/:listingEnquiryID',
        component: AppointmentComponent,
        pathMatch: 'full'
    }
];