import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from "@angular/router";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogComponent } from "./../common/confirmdialog/confirmdialog.component";
import { ComponentBase } from "./../common/componentBase";
import { ServiceResponse } from './../common/IDNamePair';
import { AppointmentService } from './../appointment/appointment.service';
import { AppointmentChangeService } from './../appointmentchange/appointmentchange.service';
import { IAgencyDto, IAppointmentDto, IEnquiryAppointmentDto } from './../appointment/appointment.state';
import { CommonService } from './../common/common.service';

@Component({
  selector: 'app-appointmentchange',
  templateUrl: './appointmentchange.component.html',
  styleUrls: ['./appointmentchange.component.css']
})
export class AppointmentChangeComponent extends ComponentBase implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private appointmentService: AppointmentService,
    private appointmentChangeService: AppointmentChangeService,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    super(route, snackBar, commonService);
  }

  //#region Private Variables
  private companyLogoUrl: string;
  private companyPhone: string;
  private companyEmail: string;
  private enquiryAppointment: IEnquiryAppointmentDto = {} as IEnquiryAppointmentDto;
  //#endregion

  //#region Form controls
  public readonly appointmentChangeForm = new FormGroup({
    noSuitableDatetimeFound: new FormControl(false)
  });
  get noSuitableDatetimeFound() {
    return this.appointmentChangeForm.get("noSuitableDatetimeFound") as FormControl;
  }
  //#endregion

  ngOnInit(): void {
    this.appointmentService
      .GetCompanyDetails(this.agencyKey, this.listingEnquiryID)
      .subscribe(
        (result: ServiceResponse<IAgencyDto>) => {
          if (!!result && !result.isError && !!result.response) {
            localStorage.setItem("companyLogoUrl", result.response.companyLogo);
            localStorage.setItem("companyPhone", result.response.agency.phone);
            localStorage.setItem("companyEmail", result.response.agency.email);
            this.companyLogoUrl = result.response.companyLogo;
            this.companyPhone = result.response.agency.phone;
            this.companyEmail = result.response.agency.email;
            console.log("Success: ", result);
          } else if (!!result && result.isError) {
            console.error("ERROR: ", result.message);
            this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
          }

        },
        function (error) {
          console.log("ERROR: ", error);
          this.snackBar.open(error, "Close", this.snackBarConfig);
        }
      );

    this.appointmentChangeService
      .GetAppointments(this.agencyKey, this.listingEnquiryID)
      .subscribe(
        (result: ServiceResponse<IEnquiryAppointmentDto>) => {
          if (!!result && !result.isError) {
            this.enquiryAppointment = result.response;
            console.log("Success: ", result);
          } else if (!!result && result.isError) {
            console.error("ERROR: ", result.message);
            this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
          }

        },
        function (error) {
          console.log("ERROR: ", error);
          this.snackBar.open(error, "Close", this.snackBarConfig);
        }
      );
  }

  onAppointmentBookClick(event, appointment: IAppointmentDto) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Are you sure?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appointmentChangeService
          .ChangeAppointment(this.agencyKey, this.listingEnquiryID, appointment.id)
          .subscribe(
            (result: ServiceResponse<IEnquiryAppointmentDto>) => {
              if (!!result && !result.isError) {
                this.enquiryAppointment = result.response;
                this.snackBar.open("Booking confirmed!", "Close", this.snackBarConfig);                
                console.log("Success: ", result);
              } else if (!!result && result.isError) {
                console.error("ERROR: ", result.message);
                this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
              }
            },
            function (error) {
              console.log("ERROR: ", error);
              this.snackBar.open(error, "Close", this.snackBarConfig);
            }
          );
      }
    });
  }
  onAppointmentRebookClick(event, appointment: IAppointmentDto) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Do you want to re-book this cancelled booking?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appointmentChangeService
          .RebookAppointment(this.agencyKey, this.listingEnquiryID, appointment.appointmentContactID)
          .subscribe(
            (result: ServiceResponse<IEnquiryAppointmentDto>) => {
              if (!!result && !result.isError) {
                this.enquiryAppointment = result.response;
                this.snackBar.open("Re-Booking confirmed!", "Close", this.snackBarConfig);                
                console.log("Success: ", result);
              } else if (!!result && result.isError) {
                console.error("ERROR: ", result.message);
                this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
              }
            },
            function (error) {
              console.log("ERROR: ", error);
              this.snackBar.open(error, "Close", this.snackBarConfig);
            }
          );
      }
    });
  }
  onAppointmentCancelClick(event, appointment: IAppointmentDto) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Are you sure to cancel it?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appointmentChangeService
          .CancelAppointment(this.agencyKey, this.listingEnquiryID, appointment.appointmentContactID)
          .subscribe(
            (result: ServiceResponse<boolean>) => {
              if (!!result && !result.isError) {
                if (result.response) {
                  appointment.bookingStatusID = 3;
                  this.snackBar.open("Booking cancelled", "Close", this.snackBarConfig);
                }
                console.log("Success: ", result);
              } else if (!!result && result.isError) {
                console.error("ERROR: ", result.message);
                this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
              }
            },
            function (error) {
              console.log("ERROR: ", error);
              this.snackBar.open(error, "Close", this.snackBarConfig);
            }
          );
      }
    });
  }
  onNoSuitableDatetimeSMSClick(event) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Do you want to send SMS to Lister?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appointmentChangeService
          .NoSuitableDatetimeSMS(this.agencyKey, this.listingEnquiryID)
          .subscribe(
            (result: ServiceResponse<boolean>) => {
              if (!!result && !result.isError) {
                if (result.response) {
                  this.snackBar.open("SMS has been sent to Lister", "Close", this.snackBarConfig);
                }
                console.log("Success: ", result);
              } else if (!!result && result.isError) {
                console.error("ERROR: ", result.message);
                this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
              }
            },
            function (error) {
              console.log("ERROR: ", error);
              this.snackBar.open(error, "Close", this.snackBarConfig);
            }
          );
      }
    });
  }

}
