import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Pipe,
  PipeTransform,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute, Route } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Sort, SortDirection } from "@angular/material/sort";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from "rxjs";

import { ComponentBase } from "./../common/componentBase";
import { AppointmentService } from "./appointment.service";
import { IDNamePair, ServiceResponse } from "./../common/IDNamePair";
import { IAgencyDto, IMaxAttendeesOfiBookingsDto, IEnquiryAppointmentDto, IAppointmentDto, IBookedAppointmentDto, IPrivateInspectionAppointmentDto } from "./appointment.state";
import { ConfirmDialogComponent } from "./../common/confirmdialog/confirmdialog.component";
import { CommonService } from './../common/common.service';
import { BookingChangeDialogComponent } from './component/bookingchangedialog/bookingchangedialog.component';
import { enResult } from './component/bookingchangedialog/bookingchangedialog.state';
import { SnackbarWithRefreshButtonComponent } from './../common/snackbarwithrefreshbutton/snackbarwithrefreshbutton.component';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(
    private _service: AppointmentService,
    private route: ActivatedRoute,
    private _datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    super(route, snackBar, commonService);
  }

  //#region Private Variables
  private companyLogoUrl: string;
  private companyPhone: string;
  private companyEmail: string;
  private enquiryAppointment: IEnquiryAppointmentDto = {} as IEnquiryAppointmentDto;
  private appContactBookingStatusID: number | null;
  private canBook: boolean = true;
  private showLoader: boolean;
  private isBookingCalled: boolean = false;
  //#endregion

  //#region Subcription Variables
  private getCompanyDetails_Subcription: Subscription;
  private getEnquiryDetails_Subcription: Subscription;
  private hasFutureAppointment_Subcription: Subscription;
  private bookAppointment_Subcription: Subscription;
  private HasFuturePrivateInspectionAppointment_Subcription: Subscription;
  private getMaxAttendeesAndOfiBookings_Subcription: Subscription;
  //#endregion

  //#region Snack Bar
  protected snackBarOwnConfig = {
    verticalPosition: "bottom",
    panelClass: "notifyPanel",
  } as MatSnackBarConfig;
  //#endregion

  //#region Form controls
  public readonly appointmentForm = new FormGroup({
    noSuitableDatetimeFound: new FormControl(false, Validators.required),
    contactForOfi: new FormControl(false),
    receiveInfoSimilarProperty: new FormControl(false, Validators.required),
    addAppointmentToOutlookCalendar: new FormControl(false, Validators.required),
    moveInBy: new FormControl(null, Validators.required),
    currentlyRenting: new FormControl(false, Validators.required),
    budgetFrom: new FormControl(0.00, Validators.required),
    budgetTo: new FormControl(0.00, Validators.required),
    minBedRooms: new FormControl(1, Validators.required),
    minCarPark: new FormControl(null),
    minBathRooms: new FormControl(1, Validators.required),
    intendRentalPeriodInMonths: new FormControl(null),
    buildingAreaFrom: new FormControl(null),
    buildingAreaTo: new FormControl(null),
    landAreaFrom: new FormControl(null),
    landAreaTo: new FormControl(null),
    otherNotes: new FormControl(null)
  });
  get noSuitableDatetimeFound() {
    return !!this.appointmentForm.get("noSuitableDatetimeFound") ? this.appointmentForm.get("noSuitableDatetimeFound") as FormControl : null;
  }
  get contactForOfi() {
    return !!this.appointmentForm.get("contactForOfi") ? this.appointmentForm.get("contactForOfi") as FormControl : null;
  }
  get receiveInfoSimilarProperty() {
    return !!this.appointmentForm.get("receiveInfoSimilarProperty") ? this.appointmentForm.get("receiveInfoSimilarProperty") as FormControl : null;
  }
  get addAppointmentToOutlookCalendar() {
    return !!this.appointmentForm.get("addAppointmentToOutlookCalendar") ? this.appointmentForm.get("addAppointmentToOutlookCalendar") as FormControl : null;
  }
  get moveInBy() {
    return !!this.appointmentForm.get("moveInBy") ? this.appointmentForm.get("moveInBy") as FormControl : null;
  }
  get currentlyRenting() {
    return !!this.appointmentForm.get("currentlyRenting") ? this.appointmentForm.get("currentlyRenting") as FormControl : null;
  }
  get budgetFrom() {
    return !!this.appointmentForm.get("budgetFrom") ? this.appointmentForm.get("budgetFrom") as FormControl : null;
  }
  get budgetTo() {
    return !!this.appointmentForm.get("budgetTo") ? this.appointmentForm.get("budgetTo") as FormControl : null;
  }
  get minBedRooms() {
    return !!this.appointmentForm.get("minBedRooms") ? this.appointmentForm.get("minBedRooms") as FormControl : null;
  }
  get minCarPark() {
    return !!this.appointmentForm.get("minCarPark") ? this.appointmentForm.get("minCarPark") as FormControl : null;
  }
  get minBathRooms() {
    return !!this.appointmentForm.get("minBathRooms") ? this.appointmentForm.get("minBathRooms") as FormControl : null;
  }
  get intendRentalPeriodInMonths() {
    return !!this.appointmentForm.get("intendRentalPeriodInMonths") ? this.appointmentForm.get("intendRentalPeriodInMonths") as FormControl : null;
  }
  get buildingAreaFrom() {
    return !!this.appointmentForm.get("buildingAreaFrom") ? this.appointmentForm.get("buildingAreaFrom") as FormControl : null;
  }
  get buildingAreaTo() {
    return !!this.appointmentForm.get("buildingAreaFrom") ? this.appointmentForm.get("buildingAreaFrom") as FormControl : null;
  }
  get landAreaFrom() {
    return !!this.appointmentForm.get("landAreaFrom") ? this.appointmentForm.get("landAreaFrom") as FormControl : null;
  }
  get landAreaTo() {
    return !!this.appointmentForm.get("landAreaTo") ? this.appointmentForm.get("landAreaTo") as FormControl : null;
  }
  get otherNotes() {
    return !!this.appointmentForm.get("otherNotes") ? this.appointmentForm.get("otherNotes") as FormControl : null;
  }
  //#endregion

  //#region Properties
  get listingType(): number {
    if (!!this.departmentType) {
      if (this.departmentType.trisetSystemID == 1 && this.departmentType.categoryID == 1) {
        return 1;
      }
      else if (this.departmentType.trisetSystemID == 2 && this.departmentType.categoryID == 1) {
        return 2;
      }
      else if (this.departmentType.trisetSystemID == 1 && this.departmentType.categoryID == 2 && this.departmentType.saleMethodID == 1) {
        return 3;
      }
      else if (this.departmentType.trisetSystemID == 1 && this.departmentType.categoryID == 2 && this.departmentType.saleMethodID == 2) {
        return 4;
      }
      else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }
  get isAppointmentSelected(): boolean {
    let selected: boolean = false;
    if (!!this.enquiryAppointment && !!this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length) {
      selected = this.enquiryAppointment.appointments.filter(app => app.selected).length > 0;
    }
    //this.contactForOfi.setValue(!selected);
    return selected;
  }
  get ofiFound(): boolean {
    return (!!this.enquiryAppointment && !!this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length);
  }
  get bookButtonText(): string {
    if (this.ofiFound) {
      return "Book";
    }
    else {
      return "Send Request Now";
    }
  }
  get isSale(): boolean {
    return this.listingType == 1;
  }
  get isRental(): boolean {
    return this.listingType == 2;
  }
  get isCommericalSale(): boolean {
    return this.listingType == 3;
  }
  get isCommericalLease(): boolean {
    return this.listingType == 4;
  }
  get noSuitableDatetimeFoundVisible(): boolean {
    if (!!this.enquiryAppointment) {
      if
        (
        this.ofiFound
        && !this.enquiryAppointment.allowPrivateInspection
        && !this.enquiryAppointment.isPrivateViewingRequired
        && !this.enquiryAppointment.ofiScheduleRequired
      ) {
        return false;
      }
      else if
        (
        this.ofiFound
        && this.enquiryAppointment.allowPrivateInspection
        && !this.enquiryAppointment.isPrivateViewingRequired
        && !this.enquiryAppointment.ofiScheduleRequired
      ) {
        return true;
      }
      else if
        (
        this.ofiFound
        && !this.enquiryAppointment.allowPrivateInspection
        && !this.enquiryAppointment.isPrivateViewingRequired
        && this.enquiryAppointment.ofiScheduleRequired
      ) {
        return false;
      }
      else if
        (
        this.ofiFound
        && this.enquiryAppointment.allowPrivateInspection
        && this.enquiryAppointment.isPrivateViewingRequired
        && !this.enquiryAppointment.ofiScheduleRequired
      ) {
        return true;
      }
      else if
        (
        this.ofiFound
        && !this.enquiryAppointment.allowPrivateInspection
        && this.enquiryAppointment.isPrivateViewingRequired
        && !this.enquiryAppointment.ofiScheduleRequired
      ) {
        return true;
      }
      else if
        (
        this.ofiFound
        && this.enquiryAppointment.allowPrivateInspection
        && !this.enquiryAppointment.isPrivateViewingRequired
        && this.enquiryAppointment.ofiScheduleRequired
      ) {
        return true;
      }
      else if
        (
        !this.ofiFound
        && this.enquiryAppointment.allowPrivateInspection
      ) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  //#endregion

  ngOnInit(): void {
    let snackConfig = this.snackBarConfig;
    snackConfig.duration = null;
    if (!(!!this.agencyKey && !!this.listingEnquiryID)) {
      this.snackBar.open("No valid listing found!!", "Close", this.snackBarConfig);
      return;
    }
    console.log("agencyKey: ", this.agencyKey, ", listingEnquiryID: ", this.listingEnquiryID);

    this.getCompanyDetails_Subcription =
      this._service
        .GetCompanyDetails(this.agencyKey, this.listingEnquiryID)
        .subscribe(
          (result: ServiceResponse<IAgencyDto>) => {
            if (!!result && !result.isError && !!result.response) {
              localStorage.setItem("companyLogoUrl", result.response.companyLogo);
              localStorage.setItem("companyPhone", result.response.agency.phone);
              localStorage.setItem("companyEmail", result.response.agency.email);
              this.companyLogoUrl = result.response.companyLogo;
              this.companyPhone = result.response.agency.phone;
              this.companyEmail = result.response.agency.email;
              console.log("Success: ", result);
            } else if (!!result && result.isError) {
              console.error("ERROR: ", result.message);
              this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
            }

          },
          function (error) {
            console.log("ERROR: ", error);
            this.snackBar.open(error, "Close", this.snackBarConfig);
          }
        );

    this.getEnquiryDetails_Subcription =
      this._service
        .GetEnquiryDetails(this.agencyKey, this.listingEnquiryID)
        .subscribe(
          (result: ServiceResponse<IEnquiryAppointmentDto>) => {
            if (!!result && !result.isError) {
              if (!!result.response) {
                this.enquiryAppointment = result.response;
                if (!!this.enquiryAppointment) {
                  if (this.enquiryAppointment.listingStatusID != 2) {
                    this.snackBar.open(`Sorry, this property is no longer available. Please check our website ${this.enquiryAppointment.agencyWebSiteAddress} for other properties that may be suitable.`, "Close", snackConfig);
                  }
                  if (this.ofiFound) {
                    this.enquiryAppointment.appointments.forEach(app => app.selected = false);
                  }
                  if (!!this.enquiryAppointment.autoSMSForInspectionManagementStartOn) {
                    if (this.enquiryAppointment.autoSMSForInspectionManagementStartOn > new Date()) {
                      this.snackBar.open(`This site cannot be reached...`, "Close", snackConfig);
                      return;
                    }
                  }
                  else {
                    this.snackBar.open(`This site cannot be reached...`, "Close", snackConfig);
                    return;
                  }
                  this.moveInBy.setValue(this.enquiryAppointment.requiredFromDate);
                  this.budgetFrom.setValue(this.enquiryAppointment.currentPriceFrom);
                  this.budgetTo.setValue(this.enquiryAppointment.currentPriceTo);
                  this.minBedRooms.setValue(this.enquiryAppointment.bedrooms);
                  this.minBathRooms.setValue(this.enquiryAppointment.bathRooms);
                  this.minCarPark.setValue(this.enquiryAppointment.carParks);
                  this.buildingAreaFrom.setValue(this.enquiryAppointment.buildingAreaFrom);
                  this.buildingAreaTo.setValue(this.enquiryAppointment.buildingAreaTo);
                  this.landAreaFrom.setValue(this.enquiryAppointment.landAreaFrom);
                  this.landAreaTo.setValue(this.enquiryAppointment.landAreaTo);
                  this.intendRentalPeriodInMonths.setValue(this.enquiryAppointment.intendLeasePeriodInMonths);
                  this.contactForOfi.setValue(this.enquiryAppointment.ofiScheduleRequired);
                  this.noSuitableDatetimeFound.setValue(this.enquiryAppointment.allowPrivateInspection && this.enquiryAppointment.isPrivateViewingRequired);
                  this.otherNotes.setValue(this.enquiryAppointment.otherNotes);
                  /*if (!(!!this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length)) {
                    this.contactForOfi.setValue(!this.enquiryAppointment.ofiScheduleRequired);
                    this.noSuitableDatetimeFound.setValue(!this.enquiryAppointment.isPrivateViewingRequired);
                  }*/

                  if
                    (
                    this.ofiFound
                    && !this.enquiryAppointment.allowPrivateInspection
                    && !this.enquiryAppointment.isPrivateViewingRequired
                    && !this.enquiryAppointment.ofiScheduleRequired
                  ) {
                    this.noSuitableDatetimeFound.setValue(false);
                    this.contactForOfi.setValue(true);
                  }
                  else if
                    (
                    this.ofiFound
                    && this.enquiryAppointment.allowPrivateInspection
                    && !this.enquiryAppointment.isPrivateViewingRequired
                    && !this.enquiryAppointment.ofiScheduleRequired
                  ) {
                    this.noSuitableDatetimeFound.setValue(false);
                    this.contactForOfi.setValue(true);
                  }
                  else if
                    (
                    this.ofiFound
                    && !this.enquiryAppointment.allowPrivateInspection
                    && !this.enquiryAppointment.isPrivateViewingRequired
                    && this.enquiryAppointment.ofiScheduleRequired
                  ) {
                    this.noSuitableDatetimeFound.setValue(false);
                    this.contactForOfi.setValue(true);
                  }
                  else if
                    (
                    this.ofiFound
                    && this.enquiryAppointment.allowPrivateInspection
                    && this.enquiryAppointment.isPrivateViewingRequired
                    && !this.enquiryAppointment.ofiScheduleRequired
                  ) {
                    this.noSuitableDatetimeFound.setValue(true);
                    this.contactForOfi.setValue(false);
                  }
                  else if
                    (
                    this.ofiFound
                    && !this.enquiryAppointment.allowPrivateInspection
                    && this.enquiryAppointment.isPrivateViewingRequired
                    && !this.enquiryAppointment.ofiScheduleRequired
                  ) {
                    this.noSuitableDatetimeFound.setValue(true);
                    this.contactForOfi.setValue(false);
                  }
                  else if
                    (
                    this.ofiFound
                    && this.enquiryAppointment.allowPrivateInspection
                    && !this.enquiryAppointment.isPrivateViewingRequired
                    && this.enquiryAppointment.ofiScheduleRequired
                  ) {
                    this.noSuitableDatetimeFound.setValue(false);
                    this.contactForOfi.setValue(true);
                  }
                  else {
                    this.noSuitableDatetimeFound.setValue(false);
                    this.contactForOfi.setValue(false);
                  }

                  this.setContactForOfi();

                  //this.noSuitableDatetimeFound.setValue(this.enquiryAppointment.allowPrivateInspection);
                  this.canBook = !this.enquiryAppointment.isPrivateViewingRequired && !this.enquiryAppointment.ofiScheduleRequired;
                  if (this.enquiryAppointment.ofiScheduleRequired) {
                    this.snackBar.open("You have already requested Future Inspection Schedule. It will be sent to you when it becomes available.", "Close", snackConfig);
                  }
                }

                this.CheckForExistingAppointmentAndProcess(false);
              }
              console.log("Success: enquiryAppointment = ", this.enquiryAppointment);
            } else if (!!result && result.isError) {
              this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
            }
          },
          function (error) {
            console.log("ERROR: ", error);
            this.snackBar.open(error, "Close", this.snackBarConfig);
          }
        );

    /*this._service
      .GetStaffNames(this.agencyKey, this.listingID, this.contactID)
      .subscribe(
        (result: ServiceResponse<IDNamePair<string>[]>) => {
          if (!!result && !result.isError) {
            let staffs = result.response;
            console.log("Success: ", result);
          } else if (!!result && result.isError) {
            console.error("ERROR: ", result.message);
            throw result.message;
          }

        },
        function (error) {
          console.log("ERROR: ", error);
        }
      );*/
    // setTimeout(() => {
    //   console.log("Department type: ", this.departmentType);
    // }, 1000);
  }
  ngOnDestroy() {
    if (!!this.getCompanyDetails_Subcription) this.getCompanyDetails_Subcription.unsubscribe();
    if (!!this.getEnquiryDetails_Subcription) this.getEnquiryDetails_Subcription.unsubscribe();
    if (!!this.hasFutureAppointment_Subcription) this.hasFutureAppointment_Subcription.unsubscribe();
    if (!!this.bookAppointment_Subcription) this.bookAppointment_Subcription.unsubscribe();
    if (!!this.HasFuturePrivateInspectionAppointment_Subcription) this.HasFuturePrivateInspectionAppointment_Subcription.unsubscribe();
    if (!!this.getMaxAttendeesAndOfiBookings_Subcription) this.getMaxAttendeesAndOfiBookings_Subcription.unsubscribe();
  }
  onNoSuitableDatetimeFoundCheckChange(event) {
    /*if (!this.isAppointmentSelected && (this.isSale || this.isRental)) {
      this.noSuitableDatetimeFound.setValue(true);
    }
    else if (this.isCommericalSale || this.isCommericalLease) {
      this.noSuitableDatetimeFound.setValue(true);
    }
    else {
      this.noSuitableDatetimeFound.setValue(false);
    }
    */
    if (this.noSuitableDatetimeFound.value == true) {
      this.contactForOfi.setValue(false);
    }
  }
  onContactForOfiCheckChange(event) {
    if (this.contactForOfi.value == true) {
      this.noSuitableDatetimeFound.setValue(false);
    }
  }
  onAppointmentClick(event, appointment: IAppointmentDto) {
    if (this.getOfiAvailability(appointment) == 1) {
      return;
    }
    let prevState: boolean = appointment.selected;
    if (!!this.enquiryAppointment && !!this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length) {
      this.enquiryAppointment.appointments.forEach(app => app.selected = false);
    }
    appointment.selected = !prevState;
    /*if (!!this.enquiryAppointment && this.enquiryAppointment.allowPrivateInspection) {
      //this.noSuitableDatetimeFound.setValue(prevState);
      this.noSuitableDatetimeFound.setValue(false);
    }
    else {
      this.noSuitableDatetimeFound.setValue(false);
    }    
    this.contactForOfi.setValue(false);*/
    if (appointment.selected) {
      this.contactForOfi.setValue(false);
      this.noSuitableDatetimeFound.setValue(false);
    }
    else {
      if (!!this.enquiryAppointment) {
        if
          (
          !this.enquiryAppointment.allowPrivateInspection
          && !this.enquiryAppointment.isPrivateViewingRequired
          && !this.enquiryAppointment.ofiScheduleRequired
        ) {
          this.noSuitableDatetimeFound.setValue(false);
          this.contactForOfi.setValue(true);
        }
        else if
          (
          this.enquiryAppointment.allowPrivateInspection &&
          !this.enquiryAppointment.isPrivateViewingRequired &&
          !this.enquiryAppointment.ofiScheduleRequired
        ) {
          this.noSuitableDatetimeFound.setValue(false);
          this.contactForOfi.setValue(true);
        }
        else if
          (
          !this.enquiryAppointment.allowPrivateInspection &&
          !this.enquiryAppointment.isPrivateViewingRequired &&
          this.enquiryAppointment.ofiScheduleRequired
        ) {
          this.noSuitableDatetimeFound.setValue(false);
          this.contactForOfi.setValue(true);
        }
        else if
          (
          this.enquiryAppointment.allowPrivateInspection &&
          this.enquiryAppointment.isPrivateViewingRequired &&
          !this.enquiryAppointment.ofiScheduleRequired
        ) {
          this.noSuitableDatetimeFound.setValue(true);
          this.contactForOfi.setValue(false);
        }
        else if
          (
          !this.enquiryAppointment.allowPrivateInspection &&
          this.enquiryAppointment.isPrivateViewingRequired &&
          !this.enquiryAppointment.ofiScheduleRequired
        ) {
          this.noSuitableDatetimeFound.setValue(true);
          this.contactForOfi.setValue(false);
        }
        else if
          (
          this.enquiryAppointment.allowPrivateInspection &&
          !this.enquiryAppointment.isPrivateViewingRequired &&
          this.enquiryAppointment.ofiScheduleRequired
        ) {
          this.noSuitableDatetimeFound.setValue(false);
          this.contactForOfi.setValue(true);
        }
        else {
          this.noSuitableDatetimeFound.setValue(false);
          this.contactForOfi.setValue(false);
        }
      }
      else {
        this.noSuitableDatetimeFound.setValue(false);
        this.contactForOfi.setValue(false);
      }
    }
  }
  onDeselectAll(event) {
    if (!!this.enquiryAppointment && !!this.enquiryAppointment.appointments) {
      this.enquiryAppointment.appointments.forEach(app => app.selected = false);
      /*if (!!this.enquiryAppointment && this.enquiryAppointment.allowPrivateInspection) {
        this.noSuitableDatetimeFound.setValue(true);
      }
      else {
        this.noSuitableDatetimeFound.setValue(false);
      }*/
      this.addAppointmentToOutlookCalendar.setValue(false);
    }
    /*if (!!this.enquiryAppointment && !this.enquiryAppointment.isPrivateViewingRequired && this.enquiryAppointment.allowPrivateInspection) {
      this.noSuitableDatetimeFound.setValue(true);
      this.contactForOfi.setValue(false);
    }
    else if (!!this.enquiryAppointment && !this.enquiryAppointment.ofiScheduleRequired) {
      this.noSuitableDatetimeFound.setValue(false);
      this.contactForOfi.setValue(true);
    }
    else {
      this.noSuitableDatetimeFound.setValue(false);
      this.contactForOfi.setValue(false);
    }*/


    if (!!this.enquiryAppointment) {
      if
        (
        !this.enquiryAppointment.allowPrivateInspection
        && !this.enquiryAppointment.isPrivateViewingRequired
        && !this.enquiryAppointment.ofiScheduleRequired
      ) {
        this.noSuitableDatetimeFound.setValue(false);
        this.contactForOfi.setValue(true);
      }
      else if
        (
        this.enquiryAppointment.allowPrivateInspection &&
        !this.enquiryAppointment.isPrivateViewingRequired &&
        !this.enquiryAppointment.ofiScheduleRequired
      ) {
        this.noSuitableDatetimeFound.setValue(false);
        this.contactForOfi.setValue(true);
      }
      else if
        (
        !this.enquiryAppointment.allowPrivateInspection &&
        !this.enquiryAppointment.isPrivateViewingRequired &&
        this.enquiryAppointment.ofiScheduleRequired
      ) {
        this.noSuitableDatetimeFound.setValue(false);
        this.contactForOfi.setValue(true);
      }
      else if
        (
        this.enquiryAppointment.allowPrivateInspection &&
        this.enquiryAppointment.isPrivateViewingRequired &&
        !this.enquiryAppointment.ofiScheduleRequired
      ) {
        this.noSuitableDatetimeFound.setValue(true);
        this.contactForOfi.setValue(false);
      }
      else if
        (
        !this.enquiryAppointment.allowPrivateInspection &&
        this.enquiryAppointment.isPrivateViewingRequired &&
        !this.enquiryAppointment.ofiScheduleRequired
      ) {
        this.noSuitableDatetimeFound.setValue(true);
        this.contactForOfi.setValue(false);
      }
      else if
        (
        this.enquiryAppointment.allowPrivateInspection &&
        !this.enquiryAppointment.isPrivateViewingRequired &&
        this.enquiryAppointment.ofiScheduleRequired
      ) {
        this.noSuitableDatetimeFound.setValue(false);
        this.contactForOfi.setValue(true);
      }
      else {
        this.noSuitableDatetimeFound.setValue(false);
        this.contactForOfi.setValue(false);
      }
    }
    else {
      this.noSuitableDatetimeFound.setValue(false);
      this.contactForOfi.setValue(false);
    }

  }

  onBookClick(event) {
    /*if (!this.isAppointmentSelected) {
      this.snackBar.open("No appointment is selected!", "Close", this.snackBarConfig);
      return;
    }*/
    if (this.isSale || this.isRental) {
      if (this.isAppointmentSelected && this.noSuitableDatetimeFound.value == true) {
        this.snackBar.open("Invalid operation!", "Close", this.snackBarConfig);
        return;
      }
    }
    else if (this.noSuitableDatetimeFound.value == false) {
      this.snackBar.open("Invalid operation!", "Close", this.snackBarConfig);
      return;
    }
    if (!this.moveInBy.valid) {
      if (this.isRental) {
        this.snackBar.open("Move in by must be filled!", "Close", this.snackBarConfig);
      }
      else {
        this.snackBar.open("Required by must be filled!", "Close", this.snackBarConfig);
      }
      return;
    }
    if (!this.budgetFrom.valid || !parseFloat(this.budgetFrom.value)) {
      if (this.isSale || this.isCommericalSale) {
        this.snackBar.open("Price range from must be filled!", "Close", this.snackBarConfig);
      }
      else if (this.isRental) {
        this.snackBar.open("Rent from must be filled!", "Close", this.snackBarConfig);
      }
      else if (this.isCommericalLease) {
        this.snackBar.open("Yearly rent from must be filled!", "Close", this.snackBarConfig);
      }
      return;
    }
    if (!this.budgetTo.valid || !parseFloat(this.budgetTo.value)) {
      if (this.isSale || this.isCommericalSale) {
        this.snackBar.open("Price range to must be filled!", "Close", this.snackBarConfig);
      }
      else if (this.isRental) {
        this.snackBar.open("Rent to must be filled!", "Close", this.snackBarConfig);
      }
      else if (this.isCommericalLease) {
        this.snackBar.open("Yearly rent to must be filled!", "Close", this.snackBarConfig);
      }
      return;
    }
    if (!this.checkBudgetRange()) {
      if (this.isSale || this.isCommericalSale) {
        this.snackBar.open("Invalid price range!", "Close", this.snackBarConfig);
      }
      else if (this.isRental) {
        this.snackBar.open("Invalid rent range!", "Close", this.snackBarConfig);
      }
      else if (this.isCommericalLease) {
        this.snackBar.open("Invalid yearly rent range!", "Close", this.snackBarConfig);
      }
      return;
    }
    if (this.isSale || this.isRental) {
      if (!this.minBedRooms.valid || !parseInt(this.minBedRooms.value)) {
        this.snackBar.open("Minimum bedrooms must be filled!", "Close", this.snackBarConfig);
        return;
      }
      if (!this.minBathRooms.valid || !parseInt(this.minBathRooms.value)) {
        this.snackBar.open("Minimum Bathrooms must be filled!", "Close", this.snackBarConfig);
        return;
      }
      /*if (!this.minCarPark.valid || !parseInt(this.minCarPark.value)) {
        this.snackBar.open("Minimum car park must be filled!", "Close", this.snackBarConfig);
        return;
      }*/
    }
    if (!this.isAppointmentSelected) {
      if (this.noSuitableDatetimeFound.value == false && this.contactForOfi.value == false) {
        this.snackBar.open("Nothing to book!!", "Close", this.snackBarConfig);
        return;
      }
    }
    if (!!this.enquiryAppointment && this.enquiryAppointment.listingStatusID != 2) {
      this.snackBar.open(`Sorry, this property is no longer available. Please check our website ${this.enquiryAppointment.agencyWebSiteAddress} for other properties that may be suitable.`, "Close", this.snackBarConfig);
      return;
    }
    if (this.isAppointmentSelected) {
      if (!!this.enquiryAppointment && !!this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length) {
        var selectedApp = this.enquiryAppointment.appointments.find(app => app.selected);
        if (!!selectedApp && selectedApp.selected) {

          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '450px',
            data: {
              title: "Confirmation",
              message: `You are about to book to attend the Open on ${this._datePipe.transform(selectedApp.startDateTime, "dd/MM/yyyy")}, ${this._datePipe.transform(selectedApp.startDateTime, "hh:mm a")} - ${this._datePipe.transform(selectedApp.endDateTime, "hh:mm a")}. Do you wish to continue?`
            }
          });
          dialogRef.afterClosed().subscribe((result: enResult) => {
            if (result) {
              this.CheckForExistingAppointmentAndProcess(true);
            }
          });
        }
      }
    }
    else {
      this.CheckForExistingAppointmentAndProcess(true);
    }
  }
  private CheckForExistingAppointmentAndProcess(book: boolean = false) {
    let snackConfig = this.snackBarConfig;
    snackConfig.duration = null;
    let isPrivateViewingRequired: boolean;
    this.hasFutureAppointment_Subcription =
      this._service
        .HasFutureAppointment(this.agencyKey, this.listingEnquiryID)
        .subscribe(
          (data: ServiceResponse<IBookedAppointmentDto>) => {
            if (!!data && !data.isError && !!data.response) {
              if (data.response.bookingStatusID == 1 || data.response.bookingStatusID == 2) {
                this.canBook = false;
                //this.snackBar.open("An appointment has been registered. You will be contacted by one of our consultants", "Close", this.snackBarConfig);
              }
            }
            if (!!data && !data.isError && !!data.response && !!data.response.bookingStatusID && data.response.bookingStatusID == 1) {

              const dialogRef = this.dialog.open(BookingChangeDialogComponent, {
                width: '450px',
                data: {
                  title: "Confirmation",
                  message: `You have already booked to attend Inspection for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")}. Do you wish to`
                }
              });

              dialogRef.afterClosed().subscribe((result: enResult) => {
                console.log('The dialog was closed: ', result);
                switch (result) {
                  case enResult.Cancel:
                    var canDialogRef = this.dialog.open(ConfirmDialogComponent, {
                      width: '350px',
                      data: {
                        title: "Confirmation",
                        message: `Are you sure you want to cancel Inspection for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")}?`
                        //message: "Are you sure you want to cancel Inspection?"
                      }
                    });
                    canDialogRef.afterClosed().subscribe((cancelDialogResult: boolean) => {
                      if (cancelDialogResult) {
                        console.log("Cancel => Change AppointmentContact.bookingStatusID to 3");
                        this._service
                          .CancelAppointments(this.agencyKey, this.listingEnquiryID)
                          .subscribe(
                            (cancelResult: ServiceResponse<boolean>) => {
                              if (!!cancelResult && !cancelResult.isError) {
                                if (cancelResult.response) {
                                  this.snackBar.open(`Your inspection booking for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} has been cancelled. Thank You.`, "", this.snackBarOwnConfig);
                                }
                                console.log("Success: ", cancelResult);
                              } else if (!!cancelResult && cancelResult.isError) {
                                console.error("ERROR: ", cancelResult.message);
                                this.snackBar.open("ERROR: " + cancelResult.message, "Close", this.snackBarConfig);
                              }
                            },
                            function (error) {
                              console.log("ERROR: ", error);
                              this.snackBar.open(error, "Close", this.snackBarConfig);
                            }
                          );
                      }
                      else {
                        this.snackBar.open(`Looking forward to meet you at  ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")}. Thank You.`, "", this.snackBarOwnConfig);
                      }
                    });

                    break;
                  case enResult.CancelAndBookAnotherTime:
                    console.log("Cancel And Book Another Time");
                    var canAndBookDialogRef = this.dialog.open(ConfirmDialogComponent, {
                      width: '350px',
                      data: {
                        title: "Confirmation",
                        message: `Are you sure you want to cancel Inspection for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")}?`
                      }
                    });
                    canAndBookDialogRef.afterClosed().subscribe((cancelAndBookDialogResult: boolean) => {
                      if (cancelAndBookDialogResult) {
                        this._service
                          .CancelAppointments(this.agencyKey, this.listingEnquiryID)
                          .subscribe(
                            (cancelResult: ServiceResponse<boolean>) => {
                              if (!!cancelResult && !cancelResult.isError) {
                                if (cancelResult.response) {
                                  //this.snackBar.open(`Your inspection booking for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} has been cancelled.  Thank You.’`, "Close", this.snackBarConfig);

                                  var canAndContinueBookDialogRef = this.dialog.open(ConfirmDialogComponent, {
                                    width: '350px',
                                    data: {
                                      title: "Confirmation",
                                      message: `Your booking for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} has been cancelled. Continue to book another appointment?`
                                    }
                                  });
                                  canAndContinueBookDialogRef.afterClosed().subscribe((canAndContunueBookDialogResult: boolean) => {
                                    if (canAndContunueBookDialogResult) {
                                      //window.location.href = `http://inspectiontime.multiarray.com/${this.agencyKey}/${this.listingEnquiryID}`;
                                      window.location.reload();
                                    }
                                    else {
                                      this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                                    }
                                  });

                                }
                                console.log("Success: ", cancelResult);
                              } else if (!!cancelResult && cancelResult.isError) {
                                console.error("ERROR: ", cancelResult.message);
                                this.snackBar.open("ERROR: " + cancelResult.message, "Close", this.snackBarConfig);
                              }
                            },
                            function (error) {
                              console.log("ERROR: ", error);
                              this.snackBar.open(error, "Close", this.snackBarConfig);
                            }
                          );
                      }
                      else {
                        this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                      }
                    });

                    break;
                  case enResult.Close:
                    console.log("Exit");
                    this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                    break;
                }
              });

            }
            else {
              this.HasFuturePrivateInspectionAppointment_Subcription =
                this._service
                  .HasFuturePrivateInspectionAppointment(this.agencyKey, this.listingEnquiryID)
                  .subscribe(
                    (data: ServiceResponse<IPrivateInspectionAppointmentDto>) => {
                      if (!!data && !data.isError && !!data.response) {
                        if (data.response.bookingStatusID == 1 || data.response.bookingStatusID == 2) {
                          this.canBook = false;
                          //this.snackBar.open("A private inspection appointment has been registered. You will be contacted by one of our consultants", "Close", this.snackBarConfig);
                        }
                      }
                      if (!!data && !data.isError && !!data.response && !!data.response.appointmentListingID && data.response.appointmentListingID.indexOf("00000000-") < 0 && !!data.response.bookingStatusID && data.response.bookingStatusID == 1) {

                        const dialogRef = this.dialog.open(BookingChangeDialogComponent, {
                          width: '450px',
                          data: {
                            title: "Confirmation",
                            message: `You have already booked to attend Inspection for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")}. Do you wish to`
                          }
                        });

                        dialogRef.afterClosed().subscribe((result: enResult) => {
                          switch (result) {
                            case enResult.Cancel:
                              var canDialogRef = this.dialog.open(ConfirmDialogComponent, {
                                width: '350px',
                                data: {
                                  title: "Confirmation",
                                  message: `Are you sure you want to cancel Inspection for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")}?`
                                  //message: "Are you sure you want to cancel Inspection?"
                                }
                              });
                              canDialogRef.afterClosed().subscribe((cancelDialogResult: boolean) => {
                                if (cancelDialogResult) {
                                  console.log("Cancel => Change AppointmentListing.bookingStatusID to 3");
                                  this._service
                                    .CancelPrivateInspectionAppointments(this.agencyKey, this.listingEnquiryID)
                                    .subscribe(
                                      (cancelResult: ServiceResponse<boolean>) => {
                                        if (!!cancelResult && !cancelResult.isError) {
                                          if (cancelResult.response) {
                                            this.snackBar.open(`Your inspection booking for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} has been cancelled. Thank You.`, "Close", this.snackBarConfig);
                                          }
                                          console.log("Success: ", cancelResult);
                                        } else if (!!cancelResult && cancelResult.isError) {
                                          console.error("ERROR: ", cancelResult.message);
                                          this.snackBar.open("ERROR: " + cancelResult.message, "Close", this.snackBarConfig);
                                        }
                                      },
                                      function (error) {
                                        console.log("ERROR: ", error);
                                        this.snackBar.open(error, "Close", this.snackBarConfig);
                                      }
                                    );
                                }
                                else {
                                  this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                                }
                              });

                              break;
                            case enResult.CancelAndBookAnotherTime:

                              var canAndBookDialogRef = this.dialog.open(ConfirmDialogComponent, {
                                width: '350px',
                                data: {
                                  title: "Confirmation",
                                  message: `Are you sure you want to cancel Inspection for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} and arrange another suitable time?`
                                  //message: "Are you sure you want to cancel Inspection?"
                                }
                              });
                              canAndBookDialogRef.afterClosed().subscribe((cancelAndBookDialogResult: boolean) => {
                                if (cancelAndBookDialogResult) {
                                  this._service
                                    .CancelPrivateInspectionAppointments(this.agencyKey, this.listingEnquiryID)
                                    .subscribe(
                                      (cancelResult: ServiceResponse<boolean>) => {
                                        if (!!cancelResult && !cancelResult.isError) {
                                          if (cancelResult.response) {
                                            //this.snackBar.open(`Your inspection booking for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} has been cancelled. One of our consultants will contact you to make another appointment.`, "Close", this.snackBarConfig);

                                            var canAndContinueBookDialogRef = this.dialog.open(ConfirmDialogComponent, {
                                              width: '350px',
                                              data: {
                                                title: "Confirmation",
                                                message: `Your inspection booking for ${data.response.textFormatLS} on ${this._datePipe.transform(data.response.startDateTime, "dd/MM/yyyy")} at ${this._datePipe.transform(data.response.startDateTime, "hh:mm a")} - ${this._datePipe.transform(data.response.endDateTime, "hh:mm a")} has been cancelled. Continue to book another appointment?`
                                              }
                                            });
                                            canAndContinueBookDialogRef.afterClosed().subscribe((canAndContunueBookDialogResult: boolean) => {
                                              if (canAndContunueBookDialogResult) {
                                                //window.location.href = `http://inspectiontime.multiarray.com/${this.agencyKey}/${this.listingEnquiryID}`;
                                                window.location.reload();
                                              }
                                              else {
                                                this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                                              }
                                            });

                                          }
                                          console.log("Success: ", cancelResult);
                                        } else if (!!cancelResult && cancelResult.isError) {
                                          console.error("ERROR: ", cancelResult.message);
                                          this.snackBar.open("ERROR: " + cancelResult.message, "Close", this.snackBarConfig);
                                        }
                                      },
                                      function (error) {
                                        console.log("ERROR: ", error);
                                        this.snackBar.open(error, "Close", this.snackBarConfig);
                                      }
                                    );
                                }
                                else {
                                  this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                                }
                              });

                              break;
                            case enResult.Close:
                              console.log("Exit");
                              this.snackBar.open("Have a Nice Day.", "Close", this.snackBarConfig);
                              break;
                          }
                        });

                      }
                      else if (!this.isBookingCalled) {
                        if (!(!!data && !!data.response && data.response.isPrivateViewingRequired)) {
                          if (!this.isAppointmentSelected) {
                            if (!!this.enquiryAppointment && !!this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length) {
                              if (!this.enquiryAppointment.ofiScheduleRequired) {
                                let keepgoing: boolean = true;
                                this.enquiryAppointment.appointments.forEach(app => {
                                  if (keepgoing) {
                                    if (this.getOfiAvailability(app) == 1) {
                                      app.selected = false;
                                    }
                                    else {
                                      app.selected = true;
                                      keepgoing = false;
                                    }
                                  }
                                });

                                //this.enquiryAppointment.appointments[0].selected = true;
                                this.noSuitableDatetimeFound.setValue(false);
                                this.contactForOfi.setValue(false);
                                this.setContactForOfi();
                              }
                            }
                          }
                        }
                        else {
                          if (!!data && !!data.response) {
                            isPrivateViewingRequired = data.response.isPrivateViewingRequired;
                            if (isPrivateViewingRequired) {
                              this.noSuitableDatetimeFound.setValue(true);
                              this.contactForOfi.setValue(false);
                              this.canBook = false;
                              this.snackBar.open("Your request for private inspection has been registered, one of our consultants will contact you. Thank You.", "Close", snackConfig);
                            }
                          }
                        }
                      }
                    },
                    function (error) {
                      console.log("ERROR: ", error);
                      this.snackBar.open(error, "Close", this.snackBarConfig);
                    });
              if (book && !isPrivateViewingRequired) {
                this.bookAppointment();
              }
            }
          },
          function (error) {
            console.log("ERROR: ", error);
            this.snackBar.open(error, "Close", this.snackBarConfig);
          }
        );
  }
  private setContactForOfi() {
    if (!!this.enquiryAppointment && this.enquiryAppointment.appointments && !!this.enquiryAppointment.appointments.length) {
      let keepgoing: boolean = true;
      this.enquiryAppointment.appointments.forEach(app => {
        if (keepgoing) {
          if (this.getOfiAvailability(app) != 1) {
            this.contactForOfi.setValue(this.enquiryAppointment.ofiScheduleRequired);
            keepgoing = false;
          }
          else {
            this.contactForOfi.setValue(true);
          }
        }
      });
    }
  }
  private book() {
    if (this.isCommericalSale || this.isCommericalLease) {
      this.contactForOfi.setValue(false);
    }
    let _noSuitableDatetimeFound: boolean = this.noSuitableDatetimeFound.value ?? false;
    let _contactForOfi: boolean = this.contactForOfi.value ?? false;
    this.showLoader = true;
    this.bookAppointment_Subcription =
      this._service
        .BookAppointment
        (
          this.agencyKey,
          this.listingEnquiryID,
          this.listingType,
          (this.isAppointmentSelected ? this.enquiryAppointment.appointments.filter(app => app.selected)[0].id : null),
          !!this.otherNotes ? this.otherNotes.value : null,
          !!this.receiveInfoSimilarProperty ? this.receiveInfoSimilarProperty.value : null,
          !!this.moveInBy ? this.moveInBy.value : null,
          (!!this.budgetFrom && !!this.budgetFrom.value) ? parseFloat(this.budgetFrom.value) : 0,
          (!!this.budgetTo && !!this.budgetTo.value) ? parseFloat(this.budgetTo.value) : 0,
          (!!this.minBedRooms && !!this.minBedRooms.value) ? parseInt(this.minBedRooms.value) : 0,
          (!!this.minBathRooms && !!this.minBathRooms.value) ? parseInt(this.minBathRooms.value) : 0,
          (!!this.minCarPark && !!this.minCarPark.value) ? parseInt(this.minCarPark.value) : 0,
          !!this.currentlyRenting ? this.currentlyRenting.value : null,
          (!!this.intendRentalPeriodInMonths && !!this.intendRentalPeriodInMonths.value) ? parseInt(this.intendRentalPeriodInMonths.value) : 0,
          !!this.buildingAreaFrom ? this.buildingAreaFrom.value : null,
          !!this.buildingAreaTo ? this.buildingAreaTo.value : null,
          !!this.landAreaFrom ? this.landAreaFrom.value : null,
          !!this.landAreaTo ? this.landAreaTo.value : null,
          !!this.noSuitableDatetimeFound ? this.noSuitableDatetimeFound.value : false,
          !!this.contactForOfi ? this.contactForOfi.value : false
        )
        .subscribe(
          (result: ServiceResponse<string>) => {
            if (!!result && !result.isError) {
              let appContactID = result.response;
              console.log("Success: ", appContactID);
              if (!!appContactID && appContactID.indexOf("00000000-") < 0) {
                localStorage.setItem("appointmentContactID", appContactID);
                this.snackBar.open("Booking confirmed!", "", this.snackBarConfig);
                this.canBook = false;
              }
              /*else if (!this.ofiFound) {
                this.snackBar.open("Thank you, we will contact you when we have scheduled the next inspection.", "Close", this.snackBarConfig);
                this.canBook = false;
              }
              else {
                this.snackBar.open("Your request for private inspection has been registered, one of our consultants will contact you. Thank You.", "Close", this.snackBarConfig);
                this.canBook = false;
              }*/
              else if (_noSuitableDatetimeFound == true) {
                this.snackBar.open("Your request for private inspection has been registered, one of our consultants will contact you. Thank You.", "Close", this.snackBarConfig);
                this.canBook = false;
              }
              else if (_contactForOfi == true) {
                this.snackBar.open("Thank you, we will contact you when we have scheduled the next inspection.", "Close", this.snackBarConfig);
                this.canBook = false;
              }
            } else if (!!result && result.isError) {
              this.snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
            }
            this.isBookingCalled = false;
            this.showLoader = false;
          },
          function (error) {
            this.showLoader = false;
            this.isBookingCalled = false;
            console.log("ERROR: ", error);
            this.snackBar.open(error, "Close", this.snackBarConfig);
          }
        );
  }

  private bookAppointment() {
    this.isBookingCalled = true;
    let appointment: IAppointmentDto = null;
    if (this.isAppointmentSelected) {
      if (!!this.enquiryAppointment.appointments.find(app => app.selected)) {
        appointment = this.enquiryAppointment.appointments.find(app => app.selected);
      }
    }
    if (this.isAppointmentSelected) {
      this.getMaxAttendeesAndOfiBookings_Subcription =
        this._service
          .GetMaxAttendeesAndOfiBookings(this.agencyKey, this.listingEnquiryID, appointment.id)
          .subscribe(
            (result: ServiceResponse<IMaxAttendeesOfiBookingsDto>) => {
              if (!!result && !result.isError) {
                if ((this.listingType == 1 || this.listingType == 2) && result.response.maxNumberOfInspectionAttendees > 0 && result.response.numberOfOfiBooking >= (result.response.maxNumberOfInspectionAttendees / 2)) {
                  this.snackBar.openFromComponent(SnackbarWithRefreshButtonComponent, {
                    data: 'Sorry, this appointment is fully booked, please try again.',
                    ...this.snackBarConfig
                  });
                  return;
                }
                else {
                  this.book();
                }
                this.isBookingCalled = false;
              }
              else if (!!result) {
                this.isBookingCalled = false;
                this.snackBar.open(result.message, "Close", this.snackBarConfig);
              }
            },
            function (error1) {
              this.isBookingCalled = false;
              console.log("ERROR: ", error1);
              this.snackBar.open(error1, "Close", this.snackBarConfig);
            });
    }
    else {
      this.book();
    }
  }
  checkBudgetRange(): boolean {
    let valid: boolean = false;
    if (!!this.budgetTo.value) {
      if (!!this.budgetFrom.value) {
        if (parseFloat(this.budgetFrom.value) > parseFloat(this.budgetTo.value)) {
          this.budgetTo.setValue(null);
        }
        else {
          valid = true;
        }
      }
      else {
        this.budgetTo.setValue(null);
      }
    }
    else {
      this.budgetTo.setValue(null);
    }
    return valid;
  }
  private getOfiAvailability(app: IAppointmentDto): number {
    let maxNumberOfInspectionAttendees = this.enquiryAppointment?.maxNumberOfInspectionAttendees ?? 0;
    if (maxNumberOfInspectionAttendees > 0) {
      if (app.numberOfOfiBooking >= (maxNumberOfInspectionAttendees / 2)) {
        return 1;
      }
      else if (app.numberOfOfiBooking == ((maxNumberOfInspectionAttendees / 2) - 2)) {
        return 2;
      }
      else if (app.numberOfOfiBooking == ((maxNumberOfInspectionAttendees / 2) - 1)) {
        return 3;
      }
      else {
        return 4;
      }
    }
    else {
      return 4;
    }
  }
  private getOfiAvailabilityMessage(app: IAppointmentDto): string {
    switch (this.getOfiAvailability(app)) {
      case 1: return "Fully Booked";
      case 2: return "2 Groups";
      case 3: return "1 Group";
      case 4: return "Available";
      default: return "";
    }
  }

  // onExitClick(event) {
  //   window.close();
  // }
}
