import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule/*, DatePipe*/ } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './../material.module';
import { AppointmentRoutes } from './appointment.routing';
import { AppointmentService } from './appointment.service';
import { AppointmentComponent } from './appointment.component';
import { BookingChangeDialogModule } from './component/bookingchangedialog/bookingchangedialog.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      BookingChangeDialogModule,
      RouterModule.forChild(AppointmentRoutes)
    ],
    providers: [AppointmentService],
    declarations: [AppointmentComponent],
    exports: []
  })
  export class AppointmentModule {}