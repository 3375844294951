export interface IDialogParam {
    title: string;
    message: string;
}
export interface IDialogResult {
    result: enResult;
}
export enum enResult {
    Undefined,
    Cancel,
    CancelAndBookAnotherTime,
    Close
}