import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

import { ComponentBase } from "./../common/componentBase";
import { CommonService } from './../common/common.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent extends ComponentBase implements OnInit {
  isExpanded = false;

  //#region Private Variables
  private companyLogoUrl: string;
  //#endregion

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private commonService: CommonService
  ) {    
    super(route, snackBar, commonService);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  ngOnInit(): void {
    setTimeout(() => {
      if (!!localStorage.getItem("companyLogoUrl")) {
        this.companyLogoUrl = localStorage.getItem("companyLogoUrl");
      }
      else {
        this.companyLogoUrl = "";
      }
    }, 2000);
  }
}
