import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DatePipe } from '@angular/common';

import { ServiceResponse, IDepartmentType } from './IDNamePair';

@Injectable()
export class CommonService {
    constructor(
        private httpClient: HttpClient,
        private _datePipe: DatePipe
      ) { }

      public GetDepartmentType(
        agencyKey: string,
        //listingID: string,
        //contactID: string
        listingEnquiryID: string
      ): Observable<ServiceResponse<IDepartmentType>> {
        return this.httpClient
          .post('/api/Appointment/GetDepartmentType', {
            agencyKey: agencyKey,
            /*listingID: listingID,
            contactID: contactID*/
            listingEnquiryID: listingEnquiryID
          })
          .pipe<ServiceResponse<IDepartmentType>>(
            map((data: ServiceResponse<IDepartmentType>) => data)
          );
      }
}