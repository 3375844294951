import { ActivatedRoute, Route } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

import { ServiceResponse, IDepartmentType } from './IDNamePair';
import { CommonService } from './common.service';

export class ComponentBase {
  protected agencyKey: string;
  //protected listingID: string;
  //protected contactID: string;
  protected listingEnquiryID: string;
  protected departmentType: IDepartmentType;
  //protected appointmentContactID: string;

  //#region Snack Bar
  protected snackBarConfig = {
    duration: 10000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel",
  } as MatSnackBarConfig;
  //#endregion

  constructor(private _route: ActivatedRoute, private _snackBar: MatSnackBar, private _commonService: CommonService) {
    this.agencyKey = this._route.snapshot.paramMap.get("agencyKey");
    //this.listingID = this._route.snapshot.paramMap.get("listingID");
    //this.contactID = this._route.snapshot.paramMap.get("contactID");
    //this.appointmentContactID = this._route.snapshot.paramMap.get("appointmentContactID");
    this.listingEnquiryID = this._route.snapshot.paramMap.get("listingEnquiryID");

    if (!!this.agencyKey) {
      localStorage.setItem("agencyKey", this.agencyKey);
    } else {
      this.agencyKey = localStorage.getItem("agencyKey");
    }
    /*if (!!this.listingID) {
      localStorage.setItem("listingID", this.listingID);
    } else {
      this.listingID = localStorage.getItem("listingID");
    }
    if (!!this.contactID) {
      localStorage.setItem("contactID", this.contactID);
    } else {
      this.contactID = localStorage.getItem("contactID");
    }
    if (!!this.appointmentContactID) {
      localStorage.setItem("appointmentContactID", this.appointmentContactID);
    } else {
      this.appointmentContactID = !!localStorage.getItem("appointmentContactID") ? localStorage.getItem("appointmentContactID") : null;
    }*/
    if (!!this.listingEnquiryID) {
      localStorage.setItem("listingEnquiryID", this.listingEnquiryID);
    } else {
      this.listingEnquiryID = !!localStorage.getItem("listingEnquiryID") ? localStorage.getItem("listingEnquiryID") : null;
    }
    //if (!!this.agencyKey && !!this.agencyKey.length && !!this.listingID && !!this.listingID.length && !!this.contactID && !!this.contactID.length) {
      if (!!this.agencyKey && !!this.agencyKey.length && !!this.listingEnquiryID) {
      this._commonService.GetDepartmentType
        (
          this.agencyKey,
          this.listingEnquiryID
        )
        .subscribe(
          (result: ServiceResponse<IDepartmentType>) => {
            if (!!result && !result.isError) {
              this.departmentType = result.response;
              console.log("DepartmentType: ", result);
            } else if (!!result && result.isError) {
              console.error("ERROR: ", result.message);
              this._snackBar.open("ERROR: " + result.message, "Close", this.snackBarConfig);
            }
          },
          function (error) {
            console.log("ERROR: ", error);
            this._snackBar.open(error, "Close", this.snackBarConfig);
          }
        );
    }

  }
}
