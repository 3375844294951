import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
    selector: 'app-snackbarwithrefreshbutton',
    styleUrls: ['snackbarwithrefreshbutton.component.css'], 
    templateUrl: 'snackbarwithrefreshbutton.component.html',
    encapsulation: ViewEncapsulation.None,
  })
  export class SnackbarWithRefreshButtonComponent {
    constructor( 
      public snackBarRef: MatSnackBarRef<SnackbarWithRefreshButtonComponent>,
      @Inject(MAT_SNACK_BAR_DATA) public data: any) { }
    
    onRefreshClick() {
      window.location.reload();
    }
  }