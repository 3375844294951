import { Inject, Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { catchError, flatMap, map } from "rxjs/operators";
import { Observable, throwError, of } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { DatePipe } from '@angular/common';

import { IDNamePair, ServiceResponse } from './../common/IDNamePair';
import { IAgencyDto, IMaxAttendeesOfiBookingsDto, IEnquiryAppointmentDto, IBookedAppointmentDto, IPrivateInspectionAppointmentDto } from './appointment.state';

@Injectable()
export class AppointmentService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private _datePipe: DatePipe
  ) { }

  public GetCompanyDetails(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<IAgencyDto>> {
    return this.httpClient
      .post('/api/Appointment/GetCompanyDetails', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<IAgencyDto>>(
        map((data: ServiceResponse<IAgencyDto>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }

  public GetMaxAttendeesAndOfiBookings(
    agencyKey: string,
    listingEnquiryID: string,
    appointmentID: string | null
  ): Observable<ServiceResponse<IMaxAttendeesOfiBookingsDto>> {
    return this.httpClient
      .post('/api/Appointment/GetMaxAttendeesAndOfiBookings', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID,
        appointmentID: appointmentID
      })
      .pipe<ServiceResponse<IMaxAttendeesOfiBookingsDto>>(
        map((data: ServiceResponse<IMaxAttendeesOfiBookingsDto>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public GetEnquiryDetails(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<IEnquiryAppointmentDto>> {
    return this.httpClient
      .post('/api/Appointment/GetEnquiryDetails', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<IEnquiryAppointmentDto>>(
        map((data: ServiceResponse<IEnquiryAppointmentDto>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public HasFutureAppointment(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<IBookedAppointmentDto>> {
    return this.httpClient
      .post('/api/Appointment/HasFutureAppointment', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<IBookedAppointmentDto>>(
        map((data: ServiceResponse<IBookedAppointmentDto>) => data)
      );
  }
  public HasFuturePrivateInspectionAppointment(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<IPrivateInspectionAppointmentDto>> {
    return this.httpClient
      .post('/api/Appointment/HasFuturePrivateInspectionAppointment', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<IPrivateInspectionAppointmentDto>>(
        map((data: ServiceResponse<IPrivateInspectionAppointmentDto>) => data)
      );
  }
  public BookAppointment(    
    agencyKey: string,
    listingEnquiryID: string,
    listingType: number,
    appointmentID: string,
    otherNotes: string,
    receiveInfoSimilarProperty: boolean,
    moveInBy: Date,
    budgetFrom: number,
    budgetTo: number,
    minBedRooms: number,
    minBathRooms: number,
    minCarPark: number,    
    currentlyRenting: boolean,
    intendRentalPeriodInMonths: number,
    buildingAreaFrom: number | null,
    buildingAreaTo: number | null,
    landAreaFrom: number | null,
    landAreaTo: number | null,
    noSuitableDatetimeFound: boolean,
    contactForOfi: boolean
  ): Observable<ServiceResponse<string>> {
    return this.httpClient
      .post('/api/Appointment/BookAppointment', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID,
        listingType: listingType,
        appointmentID: appointmentID,
        otherNotes: otherNotes,
        receiveInfoSimilarProperty: receiveInfoSimilarProperty,
        moveInBy: moveInBy,
        budgetFrom: budgetFrom,
        budgetTo: budgetTo,
        minBedRooms: minBedRooms,
        minBathRooms: minBathRooms,
        minCarPark: minCarPark,        
        currentlyRenting: currentlyRenting,
        intendRentalPeriodInMonths: intendRentalPeriodInMonths,
        buildingAreaFrom: buildingAreaFrom,
        buildingAreaTo: buildingAreaTo,
        landAreaFrom: landAreaFrom,
        landAreaTo: landAreaTo,
        noSuitableDatetimeFound: noSuitableDatetimeFound,
        contactForOfi: contactForOfi
      })
      .pipe<ServiceResponse<string>>(
        map((data: ServiceResponse<string>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public CancelAppointments(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<boolean>> {
    return this.httpClient
      .post('/api/Appointment/CancelAppointments', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<boolean>>(
        map((data: ServiceResponse<boolean>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public CancelPrivateInspectionAppointments(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<boolean>> {
    return this.httpClient
      .post('/api/Appointment/CancelPrivateInspectionAppointments', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<boolean>>(
        map((data: ServiceResponse<boolean>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public GetStaffNames(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<IDNamePair<string>[]>> {
    return this.httpClient
      .post('/api/Appointment/GetAllStaffNames', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<IDNamePair<string>[]>>(
        map((data: ServiceResponse<IDNamePair<string>[]>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
}