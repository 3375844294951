import { NgModule } from '@angular/core';
import { CommonModule/*, DatePipe*/ } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './../../material.module';
import { ConfirmDialogComponent } from './confirmdialog.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule
    ],
    providers: [/*AppointmentChangeService*/],
    declarations: [ConfirmDialogComponent],
    exports: []
  })
  export class ConfirmDialogModule {}