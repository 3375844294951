import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogParam, IDialogResult, enResult } from './bookingchangedialog.state';

@Component({
  selector: 'app-bookingchangedialog',
  templateUrl: './bookingchangedialog.component.html',
  styleUrls: ['./bookingchangedialog.component.css']
})
export class BookingChangeDialogComponent {

  private dialogResult: IDialogResult = {} as IDialogResult;
  constructor(
    public dialogRef: MatDialogRef<BookingChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public param: IDialogParam) {
    this.dialogResult.result = enResult.Undefined;
  }

  onCancel(): void {
    this.dialogResult.result = enResult.Cancel;
    this.dialogRef.close(this.dialogResult.result);
  }
  onCancelAndBookAnotherTime(): void {
    this.dialogResult.result = enResult.CancelAndBookAnotherTime;
    this.dialogRef.close(this.dialogResult.result);
  }
  onClose(): void {
    this.dialogResult.result = enResult.Close;
    this.dialogRef.close(this.dialogResult.result);
  }
}
