import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule/*, DatePipe*/ } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './../material.module';
import { AppointmentChangeRoutes } from './appointmentchange.routing';
import { AppointmentChangeService } from './appointmentchange.service';
import { AppointmentChangeComponent } from './appointmentchange.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      RouterModule.forChild(AppointmentChangeRoutes)
    ],
    providers: [AppointmentChangeService],
    declarations: [AppointmentChangeComponent],
    exports: []
  })
  export class AppointmentChangeModule {}