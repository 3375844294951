import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from "@angular/common";

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AppRoutes } from './app.routing';
import { MaterialModule } from './material.module';
import { AppointmentModule } from './appointment/appointment.module';
import { AppointmentChangeModule } from './appointmentchange/appointmentchange.module';
import { ConfirmDialogModule } from './common/confirmdialog/confirmdialog.module';
import { CommonService } from './common/common.service';
import { SnackbarWithRefreshButtonComponent } from './common/snackbarwithrefreshbutton/snackbarwithrefreshbutton.component';

//#region Extensions
declare global {
  interface Array<T> {
      sum(prop): number;
  }
  interface Date {
    toUtcYYYYMMDD: () => string;
    toYYYYMMDD: () => string;
  }
  interface Number {
    padLeft: (totalLength: number, repeatChar: string) => string;
  }
}
if (!Array.prototype.sum) {
Array.prototype.sum = function (prop) {
  var total = 0
  for ( var i = 0, _len = this.length; i < _len; i++ ) {
      total += this[i][prop]
  }
  return total
};
}
if (!Date.prototype.toUtcYYYYMMDD) {
Date.prototype.toUtcYYYYMMDD = function() {
  let dateString: string = "";
  dateString = this.getUTCFullYear() 
              + "-" 
              + (this.getUTCMonth() + 1).toString().padStart(2, "0")  
              + "-" 
              + this.getUTCDate().toString().padStart(2, "0");
  return dateString;
}
}
if (!Date.prototype.toYYYYMMDD) {
Date.prototype.toYYYYMMDD = function() {
  let dateString: string = "";
  dateString = this.getFullYear() 
              + "-" 
              + (this.getMonth() + 1).toString().padStart(2, "0")  
              + "-" 
              + this.getDate().toString().padStart(2, "0");
  return dateString;
}
}
if (!Number.prototype.padLeft) {
Number.prototype.padLeft = function(totalLength, repeatChar) {
  var  len = (totalLength - String(this).length) + 1;
  return len > 0 ? new Array(len).join(repeatChar || '0') + this : this;
}
}
//#endregion

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    SnackbarWithRefreshButtonComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    CommonModule,
    FormsModule,    
    BrowserAnimationsModule,
    /*RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
    ])*/
    MaterialModule,
    AppointmentModule,
    AppointmentChangeModule,
    ConfirmDialogModule,
    RouterModule.forRoot(AppRoutes)
  ],
  providers: [DatePipe, CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
