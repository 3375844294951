import { Inject, Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { catchError, flatMap, map } from "rxjs/operators";
import { Observable, throwError, of } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { DatePipe } from '@angular/common';

import { IDNamePair, ServiceResponse } from './../common/IDNamePair';
import { IEnquiryAppointmentDto } from './../appointment/appointment.state';

@Injectable()
export class AppointmentChangeService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private _datePipe: DatePipe
  ) { }

  public GetAppointments(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<IEnquiryAppointmentDto>> {
    return this.httpClient
      .post('/api/Appointment/GetAppointments', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<IEnquiryAppointmentDto>>(
        map((data: ServiceResponse<IEnquiryAppointmentDto>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public CancelAppointment(
    agencyKey: string,
    listingEnquiryID: string,
    appointmentContactID: string
  ): Observable<ServiceResponse<boolean>> {
    return this.httpClient
      .post('/api/Appointment/CancelAppointment', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID,
        appointmentContactID: appointmentContactID
      })
      .pipe<ServiceResponse<boolean>>(
        map((data: ServiceResponse<boolean>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public ChangeAppointment(
    agencyKey: string,
    listingEnquiryID: string,
    appointmentID: string
  ): Observable<ServiceResponse<IEnquiryAppointmentDto>> {
    return this.httpClient
      .post('/api/Appointment/ChangeAppointment', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID,
        appointmentID: appointmentID
      })
      .pipe<ServiceResponse<IEnquiryAppointmentDto>>(
        map((data: ServiceResponse<IEnquiryAppointmentDto>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public RebookAppointment(
    agencyKey: string,
    listingEnquiryID: string,
    appointmentContactID: string
  ): Observable<ServiceResponse<IEnquiryAppointmentDto>> {
    return this.httpClient
      .post('/api/Appointment/RebookAppointment', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID,
        appointmentContactID: appointmentContactID
      })
      .pipe<ServiceResponse<IEnquiryAppointmentDto>>(
        map((data: ServiceResponse<IEnquiryAppointmentDto>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }
  public NoSuitableDatetimeSMS(
    agencyKey: string,
    listingEnquiryID: string
  ): Observable<ServiceResponse<boolean>> {
    return this.httpClient
      .post('/api/Appointment/NoSuitableDatetimeSMS', {
        agencyKey: agencyKey,
        listingEnquiryID: listingEnquiryID
      })
      .pipe<ServiceResponse<boolean>>(
        map((data: ServiceResponse<boolean>) => data)
        /*, catchError((error: HttpErrorResponse) => {
          if (
            error.status === 400 ||
            error.status === 401 ||
            error.status === 403
          ) {
            return throwError(new Error("Network Error'"));
          }
          return throwError(error);
        })*/
      );
  }

}